function follow_infollow() {
    window.click_following_button = function (el) {
        var action, followable_type, msg, post_data, unique_id;
        unique_id = $(el).data('uid');
        followable_type = $(el).attr('data-info');
        msg = 'Пожалуйста войдите!';
        if ($(el).hasClass('unfollow')) {
            action = '/unfollow';
        } else {
            action = '/follow';
        }
        post_data = {
            'followable_id': unique_id,
            'followable_type': followable_type
        };
        return $.post(action, post_data, function (data) {
            var getData;
            getData = data.toString().split(',');
            if (getData[0] === 'true') {
                $(el).addClass('following_button');
                $(el).text('Подписан');
            } else if (getData[0] === 'false') {
                $(el).removeClass('following_button');
                $(el).removeClass('unfollow');
                $(el).text('Подписаться');
            }
        }).fail(function (err) {
            if (err.statusText === 'Unauthorized') {
                alert(msg);
            }
        });
    };
};


$(document).on('turbolinks:load', function () {
    $('button.followButton').hover((function () {
        if ($(this).hasClass('following_button')) {
            $(this).addClass('unfollow');
            $(this).text('Отписаться');
        }
    }), function () {
        if ($(this).hasClass('following_button')) {
            $(this).removeClass('unfollow');
            $(this).text('Подписан');
        }
    });
    follow_infollow();
});
