import 'select2';
import 'select2/dist/css/select2.css';

$(document).on('turbolinks:load', function() {
    $('#post_tag_list').select2({
            tags: true,
            tokenSeparators: [',']
        });

    $('#banner_place_tag_id').select2();
});