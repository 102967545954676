import { Controller } from 'stimulus'

export default class extends Controller {
    replay(e) {
        e.preventDefault()
        let comment_id = $(e.target).data('comment-id')
        $('.reply-form').hide()
        $('a.comment-reply.disabled').removeClass( "disabled" )
        $(e.target).addClass( "disabled" )
        $('#reply-form-' + comment_id).show()
    }
    read_more(e) {
        e.preventDefault()
        let url = $(e.target).data('url')
        Rails.ajax({ type: "GET", url: url })

    }
}
