import 'flickity/dist/flickity.css';
import 'flickity/dist/flickity.pkgd';

$(document).on('turbolinks:load', function () {

    $('.related_posts-carousel').flickity({
        cellAlign: 'left',
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        setGallerySize: true,
        adaptiveHeight: true
    });

    $('.album_images-carousel').flickity({
        pageDots: false,
        contain: true,
        setGallerySize: true,
        // lazyLoad: 1,
        adaptiveHeight: true
    });

    // $('.album_images-carousel-nav').flickity({
    //     // asNavFor: '.album_images-carousel',
    //     contain: true,
    //     pageDots: false,
    //     prevNextButtons: false
    // });

});