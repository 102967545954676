import {Controller} from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
    static targets = ["field"]

    connect() {
        this.editor = this.fieldTarget.editor
        this.initializeTribute()
    }

    disconnect() {
        this.tribute.detach(this.fieldTarget)
    }

    initializeTribute() {
        this.tribute = new Tribute({
            allowSpaces: true,
            lookup: function (person, mentionText) {
                return person.name + person.login;
            },
            values: this.fetchUsers,
          menuItemTemplate: function (item) {
            return '<div class="clearfix"><img src="'+item.original.avatar_url + '" class="author-thumb">' + item.original.name + '<div class="small text-muted">@' + item.original.login + '</div></div>'
          }
        })
        this.tribute.attach(this.fieldTarget)
        this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
        this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    }

    fetchUsers(text, callback) {
        fetch(`/mentions.json?q=${text}`)
            .then(response => response.json())
            .then(users => callback(users))
            .catch(error => callback([]))
    }

    replaced(e) {
        let mention = e.detail.item.original
        let attachment = new Trix.Attachment({
            sgid: mention.sgid,
            content: mention.content
        })
        this.editor.insertAttachment(attachment)
        this.editor.insertString(" ")
    }

    _pasteHtml(html, startPos, endPos) {
        let position = this.editor.getPosition()
        this.editor.setSelectedRange([position - endPos, position])
        this.editor.deleteInDirection("backward")
    }
}
