// HAML FRAGMENT
// %div{ data: {controller: 'clipboard' }}
//   %input{data: { target: 'clipboard.source' }, readonly: false, type: 'text', value: SecureRandom.hex(10) }
//   %button.btn.btn-sm.br-primary{data: { action: 'clipboard#copy' } }
//     .far.fa-copy

import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['source']
    copy(e) {
        e.preventDefault()
        this.sourceTarget.select()
        console.log(this.sourceTarget.value)
        document.execCommand('copy')
    }
}
