import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'type', 'external', 'internal' ]

    initialize() {
        this.toggle_block()
    }

    change_type(e) {
        e.preventDefault()
        this.toggle_block()
    }

    toggle_block() {
        // console.log(this.typeTarget.checked)
        if (this.typeTarget.checked) {
            this.internalTarget.style.display = 'none'
            this.externalTarget.style.display = ''
        } else {
            this.internalTarget.style.display = ''
            this.externalTarget.style.display = 'none'
        }
    }
}
