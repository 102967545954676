$(document).on('turbolinks:load', function () {
    $('#post_action_publish').click(function() {
        $('#post_action').val('publish');
        $('#post_main_form').submit();
        return false;
    });
    $('#post_action_reject').click(function() {
        $('#post_action').val('reject');
        $('#post_button').click();
        return false;
    });
    $('#post_action_pending').click(function() {
        $('#post_action').val('pending');
        $('#post_button').click();
        return false;
    });
    $('#post_action_draft').click(function() {
        $('#post_action').val('draft');
        $('#post_button').click();
        return false;
    });
    $('#post_action_save').click(function() {
        $('#post_button').click();
        return false;
    });
});
