import Rails from 'rails-ujs'

import  './sweet-alert-confirm'

import Turbolinks from 'turbolinks'

require("@rails/activestorage").start()

import '../stylesheets/application.scss'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap4-datetimepicker'

import './ie10-viewport-bug-workaround'
import './modal'
import './avatar'

import 'cocoon-js'

require("trix")
require("@rails/actiontext")
import './editor'

import 'bootstrap-select/dist/js/bootstrap-select'
import 'bootstrap-select/dist/css/bootstrap-select.css'

import './flickity'
import './select2'

import './post_image'
import './fancybox'

import jQueryBridget from 'jquery-bridget'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import InfiniteScroll from 'infinite-scroll'

import './sharer'
import './like_dislike'

import './follow_unfollow'
import './post'
import './navbar'
import './toastr'

import typeahead from 'typeahead.js'
import Bloodhound from 'bloodhound-js'
import Handlebars from 'handlebars'

function findVideos() {
    let videos = document.querySelectorAll('.embed_video');
    for (let i = 0; i < videos.length; i++) {
        setupVideo(videos[i]);
    }
}

function setupVideo(video) {
    let link = video.querySelector('.embed_video__link');
    if (!link) {
        return null;
    }

    let media = video.querySelector('.embed_video__media');
    let button = video.querySelector('.embed_video__button');
    let id = parseMediaURL(media);

    video.addEventListener('click', () => {
        let iframe = createIframe(id);

        link.remove();
        button.remove();
        video.appendChild(iframe);
    });

    link.removeAttribute('href');
    video.classList.add('embed_video--enabled');
}

function parseMediaURL(media) {
    let regexp = /https:\/\/i\.ytimg\.com\/vi\/([a-zA-Z0-9_-]+)\/hqdefault\.jpg/i;
    let url = media.src;
    let match = url.match(regexp);
    return match[1];
}

function createIframe(id) {
    let iframe = document.createElement('iframe');

    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('src', generateURL(id));
    iframe.classList.add('embed_video__media');

    return iframe;
}

function generateURL(id) {
    let query = '?rel=0&showinfo=0&autoplay=1';

    return 'https://www.youtube.com/embed/' + id + query;
}

$(document).on('turbolinks:load', function () {
    findVideos();
});


initialize();

function initialize() {
    Rails.start()
    Turbolinks.start()
    jQueryBridget('masonry', Masonry, $)
    jQueryBridget('infiniteScroll', InfiniteScroll, $)
    imagesLoaded.makeJQueryPlugin($)
}

import './google_analytics'

$(document).on('turbolinks:load', function () {

    var lastScrollTop = 0;
    var $navbar = $('.navbar');

    $(window).scroll(function(event){
        var st = $(this).scrollTop();
        if (st > lastScrollTop && st >= 50) {
            $navbar.hide();
        } else {
            $navbar.show();
        }
        lastScrollTop = st;
    });

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    let grid = $('.posts-container').masonry({
        gutter: 20,
        transitionDuration: 0
    });
    let msnry = grid.data('masonry');

    InfiniteScroll.imagesLoaded = imagesLoaded;

    grid.imagesLoaded(function () {
        grid.masonry('layout')
    });

    grid.infiniteScroll({
        path: ".pagination a[rel=next]",
        append: ".card-post",
        hideNav: '.pagination',
        history: false,
        prefill: true,
        status: '.page-load-status',
        isAnimated: false,
        outlayer: msnry
    });

    grid.on('append.infiniteScroll', function (event, response, path, items) {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    });


    var $paginationcontainer = $('.pagination-container').infiniteScroll({
        path: ".pagination a[rel=next]",
        append: ".pagination-item",
        hideNav: '.pagination',
        history: false,
        prefill: true,
        status: '.page-load-status',
        isAnimated: false
    });

    $paginationcontainer.on('append.infiniteScroll', function (event, response, path, items) {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    });


    $('.datetimepicker').datetimepicker({
        locale: 'ru',
        format: 'YYYY-MM-DD HH:mm',
        icons: {
            previous: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
            time: 'far fa-clock',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            date: 'far fa-calendar'
        },
        // minDate: Date.now()
    });

    $('.birthdaypicker').datetimepicker({
        locale: 'ru',
        format: 'YYYY-MM-DD',
        icons: {
            previous: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
            time: 'far fa-clock',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            date: 'far fa-calendar'
        }
    });

    var users = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/users/autocomplete?q=%QUERY',
            wildcard: '%QUERY'
        }
    });

    $('.autocomplete_user_name').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        source: users,
        display: 'name',
        limit: 100,
        templates: {
            suggestion: Handlebars.compile('<div class="clearfix"><img src="{{avatar}}" class="author-thumb">{{name}}<div class="small text-muted">@{{login}}</div></div>')
        }
    }).on('typeahead:select', function (ev, suggestion) {
        $('.autocomplete_user_id').val(suggestion.id);
    });

    $('.conversation_autocomplete_user_name').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        source: users,
        display: 'name',
        limit: 100,
        templates: {
            suggestion: Handlebars.compile('<div class="clearfix"><img src="{{avatar}}" class="author-thumb">{{name}}<div class="small text-muted">@{{login}}</div></div>')
        }
    }).on('typeahead:select', function (ev, suggestion) {
        $('.conversation_autocomplete_user_id').val(suggestion.id);
        this.form.submit();
    });

    var posts = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/posts/autocomplete?q=%QUERY',
            wildcard: '%QUERY'
        }
    });

    var search = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/search/autocomplete?q=%QUERY',
            wildcard: '%QUERY'
        }
    });

    $('.autocomplete_post_title').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        source: posts,
        display: 'title',
        limit: 100,
        templates: {
            suggestion: Handlebars.compile('<div class="clearfix"><img src="{{image}}" class="author-thumb">{{title}}<div class="small text-muted">@{{author}}</div></div>')
        }
    }).on('typeahead:select', function (ev, suggestion) {
        $(this).closest('.related_post-fields').find('.autocomplete_post_id').val(suggestion.id);
    });

    $('.search_query').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        source: search,
        display: 'title',
        limit: 100,
        templates: {
            suggestion: Handlebars.compile('<div class="clearfix">{{#if image}}<img src="{{image}}" class="post-thumb">{{else}}<img src="{{avatar}}" class="author-thumb">{{/if}}{{title}}<div class="small text-muted"><span class="far fa-user"></span> {{author}}, <span class="far fa-clock"></span> {{published_at}}</div></div>')
        }
    }).on('typeahead:select', function (ev, suggestion) {
        $(this).closest('form').submit();
    });;

    $('#related_posts').on('cocoon:after-insert', function (e, insertedItem) {
        $(insertedItem).find('.autocomplete_post_title').typeahead({
            hint: true,
            highlight: true,
            minLength: 3
        }, {
            source: posts,
            display: 'title',
            limit: 100,
            templates: {
                suggestion: Handlebars.compile('<div class="clearfix">{{#if image}}<img src="{{image}}" class="post-thumb">{{else}}<img src="{{avatar}}" class="author-thumb">{{/if}}{{title}}<div class="small text-muted"><span class="far fa-user"></span> {{author}}, <span class="far fa-clock"></span> {{published_at}}</div></div>')
            }
        }).on('typeahead:select', function (ev, suggestion) {
            $(this).closest('.nested-fields').find('.autocomplete_post_id').val(suggestion.id);
        });
    });
    $('#event_schedules').on('cocoon:after-insert', function (e, insertedItem) {
        $('.datetimepicker').datetimepicker({
            locale: 'ru',
            format: 'YYYY-MM-DD HH:mm',
            icons: {
                previous: 'fas fa-arrow-left',
                next: 'fas fa-arrow-right',
                time: 'far fa-clock',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                date: 'far fa-calendar'

            }
        });
    });

    function updateNextURL(doc) {
        if ($(doc).find('.previous_post a[rel=prev]').length ) {
            nextURL = $(doc).find('.previous_post a[rel=prev]').attr('href');
            if (nextURL.indexOf('?') != -1) {
                nextURL = nextURL + '&previous_post=1';
            } else {
                nextURL = nextURL + '?previous_post=1';
            }
        } else {
            nextURL = 'end_of_posts?previous_post=1';
        }
    };

    if ($('.js-posts-container').data('endless') == true) {
        var nextURL;

        updateNextURL(document);

        var $jspostscontainer = $('.js-posts-container').infiniteScroll({
            path: function () {
                return nextURL;
            },
            append: '.single-post-pagination-item',
            hideNav: '.previous_post',
            history: false,
            prefill: true,
            status: '.page-load-status',
            // loadOnScroll: false,
            isAnimated: false
        });


        // var $viewMoreButton = $('.view-more-button');
        //
        // $viewMoreButton.on( 'click', function() {
        //     // load next page
        //     $jspostscontainer.infiniteScroll('loadNextPage');
        //     // enable loading on scroll
        //     $jspostscontainer.infiniteScroll( 'option', {
        //         loadOnScroll: true,
        //     });
        //     // hide button
        //     $viewMoreButton.hide();
        // });

        $jspostscontainer.on('load.infiniteScroll', function (event, response) {
            updateNextURL(response);
        });
        $jspostscontainer.on('append.infiniteScroll', function (event, response, path, items) {
            $('a.fancybox').fancybox({
                parent: 'body',
                type        : 'image',
                openEffect  : 'none',
                closeEffect : 'none'
            });
            window.Sharer.init();
            $('button.followButton').hover((function () {
                if ($(this).hasClass('following_button')) {
                    $(this).addClass('unfollow');
                    $(this).text('Отписаться');
                }
            }), function () {
                if ($(this).hasClass('following_button')) {
                    $(this).removeClass('unfollow');
                    $(this).text('Подписан');
                }
            });
            $('[data-toggle="tooltip"]').tooltip();
            $('[data-toggle="popover"]').popover();
            findVideos();
            $('.related_posts-carousel').flickity({
                cellAlign: 'left',
                contain: true,
                prevNextButtons: true,
                pageDots: false,
                setGallerySize: true,
                adaptiveHeight: true
            });
            $('.album_images-carousel').flickity({
                pageDots: false,
                contain: true,
                setGallerySize: true,
                // lazyLoad: 1,
                adaptiveHeight: true
            });
            // $('.album_images-carousel-nav').flickity({
            //     asNavFor:  '.album_images-carousel',
            //     contain: true,
            //     pageDots: false,
            //     prevNextButtons: false
            // });
            // window.history.pushState("object or string", "Title", nextURL);
        });
    };


    // window.MiniProfiler;

    $('#new_message').on('ajax:success', function(a, b,c ) {
        $(this).find('input[type="text"]').val('');
    });

});

require("channels")
require("chartkick")
require("chart.js")

import './direct_uploads'
import "controllers"
