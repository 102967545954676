$(document).on('turbolinks:load', function() {
    var nHtmlNode = document.documentElement,
        jNavbar = $('.navbar'),
        jCollapseToggler = $('.js-collapse-toggler');

    nHtmlNode.style.overflow = '';

    jCollapseToggler.on('touchend click', function (e) {
        e.preventDefault();

        var $this   = $(this),
            target  = $this.attr('data-target');

        if ( $this.hasClass('is-active') )
        {
            nHtmlNode.style.overflow = '';
            jNavbar.removeClass('is-expanded');
            $this.removeClass('is-active');
        }
        else
        {
            nHtmlNode.style.overflow = 'hidden';
            jNavbar.addClass('is-expanded');
            jCollapseToggler.removeClass('is-active');
            $this.addClass('is-active');
            $(target).removeClass("d-none").addClass('d-block is-visible').siblings().removeClass('d-block is-visible').addClass("d-none");
        }

        $this.blur();

        return false;
    });
});
