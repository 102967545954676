import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'type', 'post', 'album', 'video', 'poll', 'event' ]

    initialize() {
        this.toggle_block()
    }

    change_type(e) {
        e.preventDefault()
        this.toggle_block()
    }

    toggle_block() {
        console.log(this.typeTarget.value)
        if (this.typeTarget.value == 'post') {
            this.postTarget.style.display = ''
            this.albumTarget.style.display = 'none'
            this.videoTarget.style.display = 'none'
            this.pollTarget.style.display = 'none'
            this.eventTarget.style.display = 'none'
        } else if (this.typeTarget.value == 'album') {
            this.postTarget.style.display = 'none'
            this.albumTarget.style.display = ''
            this.videoTarget.style.display = 'none'
            this.pollTarget.style.display = 'none'
            this.eventTarget.style.display = 'none'
        } else if (this.typeTarget.value == 'poll') {
            this.postTarget.style.display = ''
            this.albumTarget.style.display = 'none'
            this.videoTarget.style.display = 'none'
            this.pollTarget.style.display = ''
            this.eventTarget.style.display = 'none'
        } else if (this.typeTarget.value == 'event') {
            this.postTarget.style.display = ''
            this.albumTarget.style.display = 'none'
            this.videoTarget.style.display = 'none'
            this.pollTarget.style.display = 'none'
            this.eventTarget.style.display = ''
        } else {
            this.postTarget.style.display = 'none'
            this.albumTarget.style.display = 'none'
            this.videoTarget.style.display = ''
            this.pollTarget.style.display = 'none'
            this.eventTarget.style.display = 'none'
        }
    }
}
