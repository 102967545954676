import 'fancybox/dist/css/jquery.fancybox.css'
import 'fancybox/dist/js/jquery.fancybox'

$(document).on('turbolinks:load', function () {
    $("a.fancybox").fancybox({
        parent: 'body',
        type        : 'image',
        openEffect  : 'none',
        closeEffect : 'none'
    });
});
