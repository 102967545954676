import consumer from "./consumer"

$(document).on('turbolinks:load', function() {
  $('[data-channel-subscribe="conversation"]').each(function(index, element) {
    var $element = $(element),
        conversation_id = $element.data('conversation-id'),
        messageTemplate = $('[data-role="message-template"]');

    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)

    consumer.subscriptions.create(
      {
        channel: "ConversationChannel",
        conversation: conversation_id
      },
      {
        received: function(data) {
          var content = messageTemplate.children().clone(true, true);
          content.find('[data-role="user-avatar"]').attr('src', data.user_avatar_url);
          content.find('[data-role="user-avatar"]').attr('data-original-title', data.user_display_name);
          content.find('[data-role="message-delete"]').attr('href', data.message_path);
          content.find('[data-role="message-text"]').text(data.message);
          content.find('[data-role="message-date"]').text(data.updated_date);
          $element.append(content);
          $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
          $('[data-toggle="tooltip"]').tooltip();
          $('[data-toggle="popover"]').popover();
        }
      }
    );
  });
});
