window.click_voiting_button = function(el) {
    var action, clicked_button, like_state, likeable_type, msg, post_data, unique_id;
    clicked_button = $(el).children().attr('class');
    unique_id = $(el).parent().attr('id');
    likeable_type = $(el).parent().attr('data-info');
    like_state = void 0;
    msg = 'Пожалуйста авторизуйтесь, чтобы голосовать!';
    if (clicked_button === 'down_button fas fa-thumbs-down') {
        action = '/downvote';
    } else if (clicked_button === 'up_button fas fa-thumbs-up') {
        action = '/upvote';
    }
    post_data = {
        'likeable_id': unique_id,
        'likeable_type': likeable_type
    };
    return $.post(action, post_data, function(data) {
        var getData;
        getData = data.toString().split(',');
        $('#' + unique_id + ' .up_votes').text(getData[0]);
        $('#' + unique_id + ' .down_votes').text(getData[1]);
        $('.' + likeable_type.toLowerCase() + '_' + unique_id + '_votes_score').text(getData[2]);
    }).fail(function(err) {
        if (err.statusText === 'Unauthorized') {
            alert(msg);
        }
    });
};