import Trix from "trix"

Trix.config.toolbar.getDefaultHTML = () => {
    return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Жирный" tabindex="-1">Жирный</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Наклонный" tabindex="-1">Наклонный</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="Зачеркнутый" tabindex="-1">Зачеркнутый</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Ссылка" tabindex="-1">Ссылка</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="Заголовок" tabindex="-1">Заголовок</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Цитата" tabindex="-1">Цитата</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="Код" tabindex="-1">Код</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Маркерованный список" tabindex="-1">Маркерованный список</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Нумерованный список" tabindex="-1">Нумерованный список</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Уменьшить уровень" tabindex="-1">Уменьшить уровень</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Увеличить уровень" tabindex="-1">Увеличить уровень</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Удалить" tabindex="-1">Удалить</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Вернуть" tabindex="-1">Вернуть</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="Введите ссылку…" aria-label="Ссылка" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="Ссылка" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="Удалить ссылку" data-trix-method="removeAttribute">
          </div>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new">
            Хотите ли вы вставить медиа с этого сайта?
            <div class="trix-button-group">
              <input class="btn btn-sm" type="button" data-behavior="embed_url" value="Да, встроить">
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}

document.addEventListener('trix-initialize', function(e) {
    let editor  = e.target;
    let toolbar = editor.toolbarElement;
    let ttools  = toolbar.querySelector(".trix-button-group--text-tools");
    let dialogs = toolbar.querySelector(".trix-dialogs");
    let trixId  = editor.trixId;

    let buttonContent = `
    <button type="button"
      class="trix-button trix-button--icon trix-button--icon-attach"
      data-trix-attribute="attach"
      data-trix-key="+" title="Прекрипить файл" tabindex="-1">
    </button>
  `;

    let dialogContent = `
    <div class="trix-dialog trix-dialog--attach" data-trix-dialog="attach" data-trix-dialog-attribute="attach">
      <div class="trix-dialog__attach-fields">
        <input type="file" class="trix-input trix-input--dialog">
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog"
            onclick="
              var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
              var fileElm = this.parentElement.parentElement.querySelector('input[type=\\'file\\']');
              if ( fileElm.files.length == 0 ) {
                console.log('nothing selected');
                return;
              }
              var file = fileElm.files[0];
              trix.editor.insertFile(file);
            "
            value="Прикрепить" data-trix-method="removeAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="Отменить" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  `;
    // add attach icon button
    ttools.insertAdjacentHTML("beforeend", buttonContent);
    // add dialog
    dialogs.insertAdjacentHTML("beforeend", dialogContent);
});

var attributes;

Trix.config.blockAttributes = attributes = {
    "default": {
        tagName: "div",
        parse: false
    },
    quote: {
        tagName: "blockquote",
        nestable: true
    },
    heading1: {
        tagName: "h2",
        terminal: true,
        breakOnReturn: true,
        group: false
    },
    code: {
        tagName: "pre",
        terminal: true,
        text: {
            plaintext: true
        }
    },
    bulletList: {
        tagName: "ul",
        parse: false
    },
    bullet: {
        tagName: "li",
        listAttribute: "bulletList",
        group: false,
        nestable: true,
        test: function(element) {
            return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
        }
    },
    numberList: {
        tagName: "ol",
        parse: false
    },
    number: {
        tagName: "li",
        listAttribute: "numberList",
        group: false,
        nestable: true,
        test: function(element) {
            return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
        }
    },
    attachmentGallery: {
        tagName: "div",
        exclusive: true,
        terminal: true,
        parse: false,
        group: false
    }
};