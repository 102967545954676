import Swal from 'sweetalert2/dist/sweetalert2.all'
import 'sweetalert2/src/sweetalert2.scss'

const handleConfirm = function(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element)
    }
}

const allowAction = element => {
    if (element.getAttribute('data-confirm-swal') === null) {
        return true
    }
    showConfirmationDialog(element)
    return false
}

// Display the confirmation dialog
const showConfirmationDialog = element => {
    const message = element.getAttribute('data-confirm-swal')
    const text = element.getAttribute('data-text')
    const icon = element.getAttribute('data-icon')

    Swal.fire({
        title: message ||  'Вы уверены?',
        text: text || '',
        icon: icon || 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отменить',
    }).then(result => confirmed(element, result))
}

const confirmed = (element, result) => {
    if (result.value) {
        element.removeAttribute('data-confirm-swal')
        element.click()
    }
}

Rails.delegate(document, '[data-confirm-swal]', 'click', handleConfirm)
