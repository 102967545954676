import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'format', 'image', 'html5' ]

    initialize() {
        this.toggle_block()
    }

    change_format(e) {
        e.preventDefault()
        this.toggle_block()
    }

    toggle_block() {
        console.log(this.formatTarget.value == 'html5')
        if (this.formatTarget.value == 'html5') {
            this.imageTarget.style.display = 'none'
            this.html5Target.style.display = ''
        } else {
            this.imageTarget.style.display = ''
            this.html5Target.style.display = 'none'
        }
    }
}
